@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

body {
	overflow-y: overlay;
}
